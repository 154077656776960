type Preset = 'video' | 'player' | 'xs' | 'xs-square' | 'sm' | 'sm-square' | 'md' | 'md-square' | 'lg' | 'lg-square' | 'xl' | 'xl-square'

export default (id: string, preset?: Preset) => {
  if (preset === 'player')
    return `https://iframe.mediadelivery.net/embed/${import.meta.env.VITE_BUNNY_LIBRARY_ID}/${id}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`
  if (preset === 'video')
    return `https://${import.meta.env.VITE_BUNNY_MEDIA_PULLZONE}.b-cdn.net/${id}/thumbnail.jpg`
  if (preset)
    return `https://directus.oceana.one/public-assets/${id}?key=${preset}`
  return `https://directus.oceana.one/public-assets/${id}`
}
